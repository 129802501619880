/** @jsx jsx */
import { jsx } from "theme-ui"

const FilmEnprodCollection = props => (
  <div
    {...props}
    sx={{
      "@supports (display: grid)": {
        display: "grid",
        gap: ["10px", "20px"],
        gridTemplateColumns: [
          "repeat(auto-fill, minmax(180px, 1fr))",
          "repeat(auto-fill, minmax(260px, 1fr))",
          "repeat(auto-fill, minmax(260px, 1fr))",
        ],
      },
      "@supports not (display: grid)": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      },
    }}
  />
)

export default FilmEnprodCollection
