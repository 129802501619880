/** @jsx jsx */
import { graphql } from "gatsby"
import { jsx } from "theme-ui"
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs"
import "@reach/tabs/styles.css"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Container from "../components/container"
import FilmCollection from "../components/film-collection"
import FilmItem from "../components/film-item"
import FilmItemEnprod from "../components/film-item-enprod"
import FilmEnprodCollection from "../components/film-enprod-collection"
// import PersonneCollection from "../components/personne-collection"
// import PersonneItem from "../components/personne-item"

const IndexPage = ({ data, ...props }) => {
  return (
    <Layout>
      <SEO title="Films" />
      <Helmet>
        <link rel="canonical" href={"https://lesfilmspelleas.com/film/"} />
      </Helmet>
      <Section.Title1 sx={{ mt: 5, mb: 4 }}>FILMS</Section.Title1>
      <Tabs
        sx={{
          mt: 4,
          "> div": {
            backgroundColor: "rgba(255, 255, 255, 0.97)",
            width: "100%",
          },
        }}
        defaultIndex={props.location.hash === "#enproduction" ? 1 : 0}
      >
        <TabList
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: ["10%", "20%", "30%"],
            position: "sticky",
            top: "61px",
            zIndex: 10,
            mb: 4,
          }}
        >
          <Tab
            sx={{
              display: "block",
              color: "gray",
              outline: "none",
              ":active": {
                backgroundColor: "inherit",
              },
            }}
          >
            SORTIS
          </Tab>
          <Tab
            sx={{
              display: "block",
              color: "gray",
              outline: "none",
              ":active": {
                backgroundColor: "inherit",
              },
            }}
          >
            EN PRODUCTION
          </Tab>
        </TabList>
        <TabPanels sx={{ py: 0 }}>
          <TabPanel>
            <Section isGrey={false}>
              <Container isFull={true}>
                <FilmCollection>
                  {data.films.nodes.map(film => (
                    <FilmItem isFluid={true} key={film.id} film={film} />
                  ))}
                </FilmCollection>
              </Container>
            </Section>
          </TabPanel>

          <TabPanel>
            <Section isGrey={false}>
              <Container isFull={false}>
                <FilmEnprodCollection>
                  {data.filmsSoon.nodes.map(film => (
                    <FilmItemEnprod isFluid={true} key={film.id} film={film} />
                  ))}
                </FilmEnprodCollection>
              </Container>
            </Section>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  )
}

export const query = graphql`
  query {
    films: allBubbleCreation(
      sort: { fields: Sortie, order: DESC }
      filter: {
        isArtNum: { eq: false }
        # Affiche_optimized: { ne: null }
        DELETED: { ne: true }
        Statut_: { eq: "1555693173056x139561298485978430" } # films sortis
      }
      limit: 100
    ) {
      nodes {
        id
        Titre_FR
        slug
        Affiche_optimized {
          childImageSharp {
            fluid(
              maxWidth: 210
              maxHeight: 290
              quality: 85
              cropFocus: CENTER
            ) {
              # ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    filmsSoon: allBubbleCreation(
      filter: { Statut_: { ne: "1555693173056x139561298485978430" } } # films en développement
      sort: { fields: Sortie, order: DESC }
    ) {
      nodes {
        Titre_FR
        slug
        id
        EnProdMedias {
          Titre
          Image_optimized {
            childImageSharp {
              fluid(maxWidth: 170, maxHeight: 170, quality: 85) {
                # ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluid
              }
            }
          }
          Image
        }

        Realisateurs {
          Nom
          CACHE_Nom_complet
          slug
          Image_principale_optimized {
            childImageSharp {
              fluid(maxWidth: 170, maxHeight: 170, quality: 85) {
                # ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
